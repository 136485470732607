import firebase from 'firebase';
import { BaseData } from './BaseData';

export interface Region extends BaseData {
  name: RegionName;
  cohorts: string[];
  startDateTime: firebase.firestore.Timestamp;
  endDateTime: firebase.firestore.Timestamp;
  isLocked: boolean;
  isOpenForVoting: boolean;
  isProvincials: boolean;
  liveStreamUrlString: string;
  photoUrlString: string;
  regionId: string;
}

export enum RegionName {
  Divisional1 = 'Division 1',
  Divisional2 = 'Division 2',
  Provincial = 'Provincials',
}
