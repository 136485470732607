import { memo } from 'react';
import classNames from 'classnames';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import './index.scss';

interface Props {
  className?: string;
  url: string;
  title: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
}

export const VideoThumbnail = memo(function ({
  className = '',
  url,
  title,
  text,
  onClick,
  disabled = false,
  href = '',
}: Props) {
  if (!disabled && href.length === 0) {
    return (
      <Row className={classNames('video-thumbnail', className)} onClick={disabled ? undefined : onClick}>
        <Col xs="4" md="5">
          <ResponsiveEmbed className="img-video" aspectRatio="16by9">
            <iframe title={title} width="560" height="315" src={url} />
          </ResponsiveEmbed>
        </Col>
        <Col xs="8" md="7">
          <h5 className="title">{title}</h5>
          <p className="text">{text}</p>
        </Col>
      </Row>
    );
  }

  return (
    <a href={href.length === 0 ? undefined : href} target="_blank" rel="noopener noreferrer">
      <Row className={classNames('video-thumbnail', className)} onClick={disabled ? undefined : onClick}>
        <Col xs="4" md="5">
          <Image className="img-video" src={url} fluid />
        </Col>
        <Col xs="8" md="7">
          <h5 className="title">{title}</h5>
          <p className="text">{text}</p>
        </Col>
      </Row>
    </a>
  );
});
