import { config } from '../config';

export enum ImagePath {
  About = '/about.png',
  ComingSoon = '/comingsoon.svg',
  Hero = '/hero.svg',
  Logo = '/logo.svg',
  PlaceholderLandscape = '/placeholders/landscape.svg',
  PlaceholderSquare = '/placeholders/square.svg',
  PlaceholderVideo = '/placeholders/video.svg',
}

export const imageSrcMap = new Map(Object.values(ImagePath).map((path) => [path, `${config.assets}${path}`]));
