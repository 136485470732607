import { memo } from 'react';
import classNames from 'classnames';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';

interface Props {
  className?: string;
  title: string;
  src: string;
}

export const VideoFrame = memo(function ({ className, title, src }: Props) {
  return (
    <ResponsiveEmbed aspectRatio="16by9" className={classNames('bg-white', className)}>
      <iframe
        title={title}
        width="560"
        height="315"
        src={src}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </ResponsiveEmbed>
  );
});
