import { Suspense } from 'react';
import { lazy, mount, Matcher } from 'navi';
import { Router, View, NotFoundBoundary } from 'react-navi';

import { Path, titleMap } from '../constants';
import { PrimaryNav, Footer, Loader, NotFound } from './shared';

const links = [Path.Root, Path.Mentor, Path.About].map((href) => ({
  href,
  title: titleMap[href],
}));

const routeMap: Record<string, Matcher<object, object>> = {
  [Path.Root]: lazy(() => import('./championships')),
  [Path.Event]: lazy(() => import('./events')),
  [Path.Team]: lazy(() => import('./teams')),
  [Path.Guest]: lazy(() => import('./guests')),
  [Path.Mentor]: lazy(() => import('./mentors')),
  [Path.About]: lazy(() => import('./about')),
  [Path.Vote]: lazy(() => import('./vote')),
};

export function App() {
  return (
    <div className="contents">
      <PrimaryNav links={links} />

      <Router routes={mount(routeMap)}>
        <Suspense fallback={<Loader />}>
          <NotFoundBoundary render={() => <NotFound />}>
            <View />
          </NotFoundBoundary>
        </Suspense>
      </Router>

      <Footer />
    </div>
  );
}
