import _startCase from 'lodash/startCase';
import { memo, useMemo } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { Path, titleMap, regionMap, ImagePath, imageSrcMap } from '../../../constants';

import './index.scss';

interface Link {
  href: Path;
  title: string;
}

interface Props {
  links: Link[];
}

export const PrimaryNav = memo(function ({ links }: Props) {
  const [championship, ...restLinks] = links;
  const voteBtn = useMemo(
    () => (
      <Nav.Link className="navbar-btn" href={Path.Vote}>
        <Button variant="primary" role="button">
          {titleMap[Path.Vote]}
        </Button>
      </Nav.Link>
    ),
    [],
  );

  return (
    <Navbar expand="lg" bg="light" sticky="top">
      <Container>
        <Navbar.Brand href={Path.Root}>
          <img src={imageSrcMap.get(ImagePath.Logo)} alt="Game of Apps" height={37} />
        </Navbar.Brand>
        {voteBtn}
        <Navbar.Toggle aria-controls="primary-nav" />
        <Navbar.Collapse id="primary-nav">
          <Nav className="ml-auto">
            <NavDropdown title={_startCase(championship.title)} id="primary-nav-dropdown">
              <NavDropdown.Item href={Path.Root}>Home</NavDropdown.Item>
              <NavDropdown.Divider />
              {Object.entries(regionMap).map(([title, href]) => (
                <NavDropdown.Item key={title} href={Path.Event + href}>
                  {title}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            {restLinks.map((link) => (
              <Nav.Link key={link.title} href={link.href}>
                {_startCase(link.title)}
              </Nav.Link>
            ))}
          </Nav>
          {voteBtn}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
});
