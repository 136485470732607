const isDevelopment = process.env.NODE_ENV === 'development';

export const firebaseConfig = {
  apiKey: isDevelopment ? process.env.REACT_APP_FIREBASE_API_KEY_DEV : process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: isDevelopment
    ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV
    : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: isDevelopment
    ? process.env.REACT_APP_FIREBASE_DATABASE_URL_DEV
    : process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: isDevelopment ? process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV : process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: isDevelopment
    ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV
    : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: isDevelopment
    ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV
    : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: isDevelopment ? process.env.REACT_APP_FIREBASE_APP_ID_DEV : process.env.REACT_APP_FIREBASE_APP_ID,
};

export const serverConfig = {
  serverUrl: isDevelopment ? process.env.REACT_APP_SERVER_URL_DEV : process.env.REACT_APP_SERVER_URL,
};

export const config = {
  assets: `${process.env.PUBLIC_URL}/assets`,
};
