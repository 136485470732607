import { memo, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  children: ReactNode;
}

export const Section = memo(function ({ className, children }: Props) {
  return <section className={classNames('section', className)}>{children}</section>;
});
